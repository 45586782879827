/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    h4: "h4",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    msub: "msub",
    mn: "mn",
    mfrac: "mfrac",
    annotation: "annotation"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "論文メモ: “ネットワーク構造を対象とした特徴量抽出とその応用”"), "\n", React.createElement(_components.h3, {
    id: "メタ情報",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BF%E6%83%85%E5%A0%B1",
    "aria-label": "メタ情報 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "メタ情報"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "論文へのリンク"), "\n"), "\n", React.createElement(_components.p, null, "http://www.orsj.or.jp/archive2/or60-8/or60_8_468.pdf"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "筆者・所属機関\n羽室　行信(関西学院大学経営戦略研究科)"), "\n"), "\n", React.createElement(_components.p, null, "中原　孝信(専修大学商学部)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "投稿日付"), "\n"), "\n", React.createElement(_components.p, null, "2015/08"), "\n", React.createElement(_components.h3, {
    id: "論文内容",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E8%AB%96%E6%96%87%E5%86%85%E5%AE%B9",
    "aria-label": "論文内容 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "論文内容"), "\n", React.createElement(_components.h4, {
    id: "概要一言まとめ",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81%E4%B8%80%E8%A8%80%E3%81%BE%E3%81%A8%E3%82%81",
    "aria-label": "概要一言まとめ permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "概要（一言まとめ）"), "\n", React.createElement(_components.p, null, "ネットワーク解析におけるグラフ構造について特徴量の抽出方法と、その応用方法をまとめている"), "\n", React.createElement(_components.p, null, "pos（購買履歴）データとtwitterデータを対象として、共起関係より距離関数を定義して、商品間、単語間の関係を表現し、各々グラフ構造について、特徴的な構造を取り出して、評価している"), "\n", React.createElement(_components.h4, {
    id: "新規性何が過去の研究に比べて凄い",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%96%B0%E8%A6%8F%E6%80%A7%E4%BD%95%E3%81%8C%E9%81%8E%E5%8E%BB%E3%81%AE%E7%A0%94%E7%A9%B6%E3%81%AB%E6%AF%94%E3%81%B9%E3%81%A6%E5%87%84%E3%81%84",
    "aria-label": "新規性何が過去の研究に比べて凄い permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "新規性（何が過去の研究に比べて凄い？）"), "\n", React.createElement(_components.p, null, "過去がわからないのでスキップ"), "\n", React.createElement(_components.h4, {
    id: "手法の概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%89%8B%E6%B3%95%E3%81%AE%E6%A6%82%E8%A6%81",
    "aria-label": "手法の概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "手法の概要"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "PMIを用いて、リンクを貼る閾値を設定することでグラフ研磨をしている。これを行うことでコミュニティ検出において、クリーク（3つ以上のノードが完全グラフとなる部分グラフ）を減らすなど、特徴的な構造を明確化している"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "NetSimile法にてネットワーク間の類似度を測定する"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "これらの手法を用いてtwitterデータからはツイートの要約とクラスタリングを。購買履歴からは店舗間の購入商品の特徴、来店頻度の高い優良顧客の判別を行っている"), "\n", React.createElement(_components.h4, {
    id: "コメント",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88",
    "aria-label": "コメント permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "コメント"), "\n", React.createElement(_components.p, null, "ネットワーク解析から、共起語のクラスタリング、商品の購入頻度、同時に買われる商品、また教師あり学習の特徴量としても使うなど、"), "\n", React.createElement(_components.p, null, "応用範囲とその考察がかかれている。グラフ研磨は定石なやり方なのか気になった"), "\n", React.createElement(_components.h4, {
    id: "関連情報あれば",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E9%96%A2%E9%80%A3%E6%83%85%E5%A0%B1%E3%81%82%E3%82%8C%E3%81%B0",
    "aria-label": "関連情報あれば permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "関連情報（あれば）"), "\n", React.createElement(_components.p, null, "なし"), "\n", React.createElement(_components.h4, {
    id: "次に読む論文",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%AC%A1%E3%81%AB%E8%AA%AD%E3%82%80%E8%AB%96%E6%96%87",
    "aria-label": "次に読む論文 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "次に読む論文"), "\n", React.createElement(_components.p, null, "未定"), "\n", React.createElement(_components.h4, {
    id: "自分なりのアウトプット",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E8%87%AA%E5%88%86%E3%81%AA%E3%82%8A%E3%81%AE%E3%82%A2%E3%82%A6%E3%83%88%E3%83%97%E3%83%83%E3%83%88",
    "aria-label": "自分なりのアウトプット permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "自分なりのアウトプット"), "\n", React.createElement(_components.p, null, "わからなかった用語とかメモしておきたい用語"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "類似度グラフ"), "\n"), "\n", React.createElement(_components.p, null, "類似度が一定以上ならリンクを貼る、などとして生成した無向グラフのこと"), "\n", React.createElement(_components.p, null, "類似度として、論文中ではPMIを用いている、他にもJaccard係数を用いることもある"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "PMI(pointwise mutual information)"), "\n"), "\n", React.createElement(_components.p, null, "ノードu、vに対して、生起確率p(u)、p(v)、共起確率p(u,v)とすると"), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "P"), React.createElement(_components.mi, null, "M"), React.createElement(_components.mi, null, "I"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "u"), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.mi, null, "v"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mo, null, "="), React.createElement(_components.msub, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "log"), React.createElement(_components.mo, null, "⁡")), React.createElement(_components.mn, null, "2")), React.createElement(_components.mfrac, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "u"), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.mi, null, "v"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")), React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "p"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "u"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mi, null, "p"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "v"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")")))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "PMI(u,v) = \\log_{2}\\frac{p(u,v)}{p(u)p(v)}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10903em"
    }
  }, "PM"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.07847em"
    }
  }, "I"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mpunct"
  }, ","), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.16666666666666666em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "v"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.53em",
      verticalAlign: "-0.52em"
    }
  }), React.createElement(_components.span, {
    className: "mop"
  }, React.createElement(_components.span, {
    className: "mop"
  }, "lo", React.createElement(_components.span, {
    style: {
      marginRight: "0.01389em"
    }
  }, "g")), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.20696799999999996em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.4558600000000004em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2"))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.24414em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.16666666666666666em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mopen nulldelimiter"
  }), React.createElement(_components.span, {
    className: "mfrac"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "1.01em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.655em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "p"), React.createElement(_components.span, {
    className: "mopen mtight"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "u"), React.createElement(_components.span, {
    className: "mclose mtight"
  }, ")"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "p"), React.createElement(_components.span, {
    className: "mopen mtight"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.03588em"
    }
  }, "v"), React.createElement(_components.span, {
    className: "mclose mtight"
  }, ")")))), React.createElement(_components.span, {
    style: {
      top: "-3.23em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "frac-line",
    style: {
      borderBottomWidth: "0.04em"
    }
  })), React.createElement(_components.span, {
    style: {
      top: "-3.485em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "3em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "p"), React.createElement(_components.span, {
    className: "mopen mtight"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "u"), React.createElement(_components.span, {
    className: "mpunct mtight"
  }, ","), React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.03588em"
    }
  }, "v"), React.createElement(_components.span, {
    className: "mclose mtight"
  }, ")"))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.52em"
    }
  }, React.createElement(_components.span))))), React.createElement(_components.span, {
    className: "mclose nulldelimiter"
  }))))))), "\n", React.createElement(_components.p, null, "で表される。大きいほど共起しやすいと言える"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "エゴネットワーク\nあるノードから1-hop(リンクを一つたどること)で到達できるノードからなる、部分グラフのこと"), "\n"), "\n", React.createElement(_components.h4, {
    id: "気になった英単語英語表現",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%B0%97%E3%81%AB%E3%81%AA%E3%81%A3%E3%81%9F%E8%8B%B1%E5%8D%98%E8%AA%9E%E8%8B%B1%E8%AA%9E%E8%A1%A8%E7%8F%BE",
    "aria-label": "気になった英単語英語表現 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "気になった英単語・英語表現"), "\n", React.createElement(_components.p, null, "日本語なのでなし"), "\n", React.createElement(_components.p, null, "フォーマットはからあげさん考案のものを参考にしている"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://karaage.hatenadiary.jp/entry/2018/08/13/000000"
  }, "https://karaage.hatenadiary.jp/entry/2018/08/13/000000)"), ")"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
